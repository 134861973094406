// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./public/fonts/Satoshi-Variable.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --doc-height: 100%;\n  --doc-height: 100%;\n  --color-primary: rgb(5 112 191);\n  --color-foreground: rgb(66, 66, 66);\n  --color-background: rgb(255, 255, 255);\n}\n\n@font-face {\n  font-family: 'Satoshi';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n  font-weight: 100 900;\n  font-style: normal;\n}\n\nbody {\n  font-size: 18px;\n  font-weight: 400;\n  font-family: 'Satoshi', sans-serif;\n  color: var(--color-light-grey);\n\n}\n\nh1,\nh2,\nh3,\np,\nli {\n  color: var(--color-foreground);\n}\n", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,+BAA+B;EAC/B,mCAAmC;EACnC,sCAAsC;AACxC;;AAEA;EACE,sBAAsB;EACtB,+DAAkE;EAClE,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kCAAkC;EAClC,8BAA8B;;AAEhC;;AAEA;;;;;EAKE,8BAA8B;AAChC","sourcesContent":[":root {\n  --doc-height: 100%;\n  --doc-height: 100%;\n  --color-primary: rgb(5 112 191);\n  --color-foreground: rgb(66, 66, 66);\n  --color-background: rgb(255, 255, 255);\n}\n\n@font-face {\n  font-family: 'Satoshi';\n  src: url('./public/fonts/Satoshi-Variable.ttf') format('truetype');\n  font-weight: 100 900;\n  font-style: normal;\n}\n\nbody {\n  font-size: 18px;\n  font-weight: 400;\n  font-family: 'Satoshi', sans-serif;\n  color: var(--color-light-grey);\n\n}\n\nh1,\nh2,\nh3,\np,\nli {\n  color: var(--color-foreground);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
