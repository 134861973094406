export const TRANSITION_SPEED = 1;
export const CONTENT_SPACING = {
    XSMALL: '0.125rem',
    SMALL: '0.25rem',
    MEDIUM: '0.5rem',
    LARGE: '1rem',
    XLARGE: '2rem',
    XXLARGE: '4rem',
    XXXLARGE: '8rem',
    XXXXLARGE: '16rem'
};
export const FONT_SIZES = {
    XSMALL: '0.7rem',
    SMALL: '1.0rem',
    MEDIUM: '1.2rem',
    LARGE: '1.5rem',
    XLARGE: '2.5rem',
    XXLARGE: '6rem'
};
export const COLORS = {
    PRIMARY: 'var(--color-primary)',
    FOREGROUND: 'var(--color-foreground)',
    BACKGROUND: 'var(--color-background)'
};
// CSS Variables don't work with media queries.
export const MOBILE_WIDTH = '768px';
export const MAX_WIDTH = '2000px';
export const Z_INDEX = {
    LOADING: 999,
    NAVIGATION: 998,
    NAVIGATION_GUTTER: 997,
    SINGLE_PHOTO_CONTROLS: 996,
    SINGLE_PHOTO: 995,
    TITLE_BAR: 994
};
